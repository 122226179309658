import React from 'react'

import image from '../images/ocf-image-02.png'

const WhatOCF = () => {
  return (
    <section className="what-ocf">
      <div className="what-ocf__inner">
        <div className="what-ocf__left">
          <h2>Cosa fa OCF</h2>
          <p>
            La funzione principale di OCF, oltre alla tenuta e gestione
            dell’Albo, è la vigilanza sui consulenti finanziari, per assicurare
            il rispetto della disciplina e la tutela degli investitori.
            L’iscrizione all’Albo è{' '}
            <strong>
              <u>obbligatoria</u>
            </strong>{' '}
            per esercitare l’attività di Consulenza Finanziaria.
          </p>
        </div>
        <div className="what-ocf__right">
          <img src={image} alt="Cosa fa OCF" />
        </div>
      </div>
    </section>
  )
}

export default WhatOCF
