import React, {useState} from 'react'

const ReadMore = ({children}) => {
  const [open, setOpen] = useState(false)

  const handleClick = e => {
    e.preventDefault()
    setOpen(!open)
  }

  return (
    <div className="readmore">
      <a
        className={`readmore__link ${open ? 'open' : ''}`}
        href="#"
        onClick={handleClick}
      >
        {open ? 'Chiudi' : 'Continua'}
      </a>
      {open && <>{children}</>}
    </div>
  )
}

export default ReadMore
