import React from 'react'

import graph from '../images/graph.svg'
import graphMobile from '../images/graph-mobile.svg'

const Numbers = () => {
  return (
    <section className="numbers">
      <div className="numbers__inner">
        <div className="numbers__header">
          <h3>
            <span>L'</span>OCF <span>in cifre</span>
          </h3>
          <h4>Esperienza, affidabilità e competenza</h4>
          <p>
            Al 31 dicembre 2020 OCF, sul territorio nazionale, poteva contare
            su:
          </p>
        </div>
        <div className="numbers__grid">
          <img className="desktop" src={graph} alt="I numeri di OCF" />
          <img className="mobile" src={graphMobile} alt="I numeri di OCF" />
        </div>
        <div className="numbers__footer">
          <p>
            Un gruppo cospicuo, per la maggioranza formato da professionisti
            esperti, con un bagaglio di competenze e affidabilità ricco e
            variegato, in grado di rispondere a tutte le richieste
            dei&nbsp;clienti.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Numbers
