import React from 'react'

import Video from './Video'
import posterFamiglie from '../images/poster-famiglie.jpg'
import posterMaturi from '../images/poster-maturi.jpg'
import posterGiovani from '../images/poster-giovani.jpg'

const All = () => {
  return (
    <section className="all">
      <div className="all__inner">
        <h3>Una soluzione per tutti</h3>
        <p>
          Proprio perché ognuno di noi ha una situazione economica diversa e
          personale, è assolutamente importante affidarsi ad un Consulente
          Finanziario che abbia tutte le competenze e gli strumenti per svolgere
          una valutazione attenta delle risorse e una ponderata analisi delle
          possibilità di investimento, in grado di tutelarne il valore e
          consolidarlo nel tempo. I Consulenti Finanziari iscritti all’albo
          rappresentano la scelta più affidabile, perché OCF ne assicura gli
          standard etici e ne certifica le capacità tecniche per garantire
          proposte realistiche, strutturate su misura per ciascun cliente.
        </p>
        <div className="all__row">
          <div className="all__video">
            <h4>Per le famiglie</h4>
            <Video ytID="zBgt2LmDlKc" poster={posterFamiglie} small />
          </div>
          <div className="all__video">
            <h4>Per i più maturi</h4>
            <Video ytID="k-pxPBsfNrc" poster={posterMaturi} small />
          </div>
          <div className="all__video">
            <h4>Per i più giovani</h4>
            <Video ytID="lb6-sQOuuKU" poster={posterGiovani} small />
          </div>
        </div>
      </div>
    </section>
  )
}

export default All
