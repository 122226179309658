import React from 'react'

import Video from './Video'
import ReadMore from './ReadMore'
import poster from '../images/poster-consulente.jpg'

const Consulente = () => {
  return (
    <section className="consulente">
      <div className="consulente__inner">
        <div className="consulente__left">
          <Video ytID="7DOodqgGlhI" poster={poster} />
        </div>
        <div className="consulente__right">
          <h3>Il Consulente Finanziario</h3>
          <p>
            Il Consulente Finanziario consiglia i clienti su come gestire il
            proprio patrimonio e investire nel modo migliore.È un esperto dei
            mercati e dei prodotti finanziari offerti da banche, istituti di
            credito e società di intermediazione: grazie a queste conoscenze,
            può consigliare e indirizzare i propri clienti verso le soluzioni
            più adatte alle loro esigenze - dal credito alla previdenza, dagli
            investimenti alla protezione assicurativa.
          </p>
          <ReadMore>
            <p>
              In base al livello di rischio che il cliente è pronto ad assumere,
              il Consulente Finanziario può proporre una pianificazione
              Finanziaria personalizzata, per raggiungere gli obiettivi definiti
              insieme. Ad esempio, il consulente finanziario abilitato
              all’offerta fuori sede promuove e colloca i servizi d’investimento
              e / o i servizi accessori presso clienti, riceve e trasmette le
              istruzioni o gli ordini dei clienti riguardanti servizi
              d’investimento o prodotti finanziari, promuove e colloca prodotti
              finanziari, presta consulenza in materia di investimenti ai
              clienti o potenziali clienti rispetto a detti prodotti o servizi
              finanziari.
            </p>
            <p>
              Il consulente finanziario abilitato all’offerta fuori sede può
              promuovere e collocare contratti relativi alla concessione di
              finanziamenti o alla prestazione di servizi di pagamento per conto
              del soggetto nell’interesse del quale esercita l’attività di
              offerta fuori sede.
              <br />
              Il consulente finanziario autonomo e le società di consulenza
              finanziaria prestano ai clienti la consulenza in materia di
              investimenti, relativamente a valori mobiliari e a quote di
              organismi di investimento collettivo, senza detenere fondi o
              titoli appartenenti ai clienti. <br />
              Gestisce poi tutta la documentazione e le pratiche amministrative
              e burocratiche correlate.
            </p>
            <p>
              Il Consulente Finanziario segue quindi l'andamento effettivo del
              piano finanziario rispetto alle previsioni: monitora le quotazioni
              in borsa di azioni, titoli e fondi in portafoglio, aggiorna
              costantemente il cliente sulla situazione attuale, verifica se
              siano necessarie delle modifiche in base ai trend del mercato e
              alle eventuali mutate condizioni del cliente. Per questo il
              Consulente Finanziario si deve mantenere informato sui nuovi
              prodotti finanziari disponibili e sulle analisi di mercato
              pubblicate da analisti e media specializzati.
            </p>
          </ReadMore>
        </div>
      </div>
    </section>
  )
}

export default Consulente
