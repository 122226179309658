import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

import WhatOCF from '../components/WhatOCF'
import Numbers from '../components/Numbers'
import Consulenza from '../components/Consulenza'
import Consulente from '../components/Consulente'
import CTA from '../components/Cta'
import All from '../components/All'

const IndexPage = () => (
  <Layout>
    <SEO title="La Consulenza Finanziaria" />
    <WhatOCF />
    <Numbers />
    <Consulenza />
    <Consulente />
    <CTA />
    <All />
  </Layout>
)

export default IndexPage
