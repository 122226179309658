import React from 'react'

import image from '../images/ocf-image-03.png'

const Consulenza = () => {
  return (
    <section className="consulenza">
      <div className="consulenza__inner">
        <div className="consulenza__left">
          <h3>La Consulenza Finanziaria</h3>
          <p>
            In un panorama economico sempre più complesso è fondamentale, per un
            risparmiatore, conoscere i meccanismi di base delle attività
            finanziarie che possono interessarlo. Di fatto, però, una cultura
            finanziaria, anche elementare, è insufficiente nelle famiglie
            italiane.
            <br />A parte quella che viene definita come "la diligenza del buon
            padre di famiglia", sembra esserci una mancanza organica di processi
            di pianificazione e controllo delle scelte finanziarie.
          </p>
        </div>
        <div className="consulenza__right">
          <img src={image} />
        </div>
      </div>
    </section>
  )
}

export default Consulenza
